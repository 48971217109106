import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DefaultUrlSerializer, Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { firstValueFrom, Subscription } from 'rxjs';
import { ReviewService } from 'src/app/services/review.service';
import { Actions, BATCH_NOTICE_DAY, BATCH_NOTICE_HOUR, BATCH_NOTICE_MINUTE, BATCH_NOTICE_WAIT_PERIOD, LdapGroups } from 'src/app/shared/constants';
import { Campaign } from '../types/campaign';
import { Segment } from '../types/segment';
import { ReducedHistory } from '../types';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  form = new FormGroup({
    ldap: new FormControl(),
    groups: this.fb.array([]) as FormArray,
  });
  campaigns: Campaign[] = [];
  openErrorModal = false;
  batchProcessRunning = false;
  isSecondWednesday = false;
  subscriptions: Subscription = new Subscription();

  //Identities
  hasWriterPermission = false;
  hasCompliancePermission = false;
  hasLegalPermission = false;
  hasApproverPermission = false;

  constructor(
    private router: Router,
    public oidcSecurityService: OidcSecurityService,
    private fb: FormBuilder,
    private reviewService: ReviewService,
  ) {
    const date = new Date();
    const day = date.getDate();
    const dayOfWeek = date.getDay();

    // Check if it's Wednesday and the day is between 8 and 14 (inclusive)
    this.isSecondWednesday = dayOfWeek === BATCH_NOTICE_DAY && day >= 8 && day <= 14;
  }

  ngOnInit(): void {
    this.form.get('ldap')?.patchValue(sessionStorage.getItem('ldapGroup'));
    this.hasWriterPermission = sessionStorage.getItem('hasWriterPermission') === 'true';
    this.hasCompliancePermission = sessionStorage.getItem('hasCompliancePermission') === 'true';
    this.hasLegalPermission = sessionStorage.getItem('hasLegalPermission') === 'true';
    this.hasApproverPermission = sessionStorage.getItem('hasApproverPermission') === 'true';
    setTimeout(() => {
      this.addGroupsToDropdown();
      this.form.value.groups.forEach((group: any) => {
        if (group === this.form.value.ldap) {
          this.form.get('ldap')?.patchValue(this.form.value.ldap);
          if (this.form.value.ldap) {
            (window as any).dashboardRequestIndicator.show();
            try {
              this.getNewCampaigns().then(() => {
                (window as any).dashboardRequestIndicator.hide();
              });
            } catch (error) {
              (window as any).dashboardRequestIndicator.hide();
              this.openErrorModal = true;
            }
          }
        }
      });
    });
  }

  async getNewCampaigns() {
    const campaigns = await firstValueFrom(this.reviewService.getCampaigns(this.form.value.ldap));
    const allMessages = await firstValueFrom(this.reviewService.getMessagesFromDb());

    campaigns.forEach((campaign) => {
      campaign.segments.forEach((segment) => {
        if (segment.group) {
          segment.messages = allMessages.filter((message) => message.segmentId === segment.id && message.status === 'approved');
        }
      });
    });
    this.campaigns = campaigns;
  }
  segmentInReview(segment: Segment): boolean {
    return segment.status?.toLowerCase() === 'message approved';
  }

  navigate(segment: Segment, campaign: Campaign, perk: string) {
    const urlSerializer = new DefaultUrlSerializer();
    const encodedURIComponent = encodeURIComponent(perk);
    const parsedPath = urlSerializer.parse(`/${encodedURIComponent}/review/segment/${segment.id}`);
    const serializedPath = urlSerializer.serialize(parsedPath);
    this.router.navigate([serializedPath], { state: { segment, campaign, group: this.form.value.ldap } });
  }

  async cancelMessage(segment: Segment) {
    const message = segment.messages?.[0];
    if (message) {
      (window as any).dashboardRequestIndicator.show();
      const history: ReducedHistory = {
        user: sessionStorage.getItem('displayName') ?? '',
        group: LdapGroups.Approver,
        recipient: LdapGroups.Approver,
        action: Actions.Sent,
        description: 'Approver cancelling message',
        userCount: segment.userCount,
      };
      message.status = LdapGroups.Approver;
      message.inReview = false;
      await firstValueFrom(this.reviewService.updateMessageStatus(message, history, segment.id));
      await this.getNewCampaigns();
      (window as any).dashboardRequestIndicator.hide();
    }
  }

  roleSelected() {
    !this.form.value.ldap ? undefined : sessionStorage.setItem('ldapGroup', this.form.value.ldap);
    if (this.form.value.ldap) {
      (window as any).dashboardRequestIndicator.show();
      this.subscriptions.add(
        this.reviewService.getCampaigns(this.form.value.ldap).subscribe({
          next: (campaigns: Campaign[]) => {
            (window as any).dashboardRequestIndicator.hide();
            this.campaigns = campaigns;
          },
          error: () => {
            (window as any).dashboardRequestIndicator.hide();
            this.openErrorModal = true;
          },
        }),
      );
    }
  }

  addGroupsToDropdown() {
    this.hasWriterPermission ? (this.form.value.groups as FormArray).push(LdapGroups.Writer) : undefined;
    this.hasCompliancePermission ? (this.form.value.groups as FormArray).push(LdapGroups.Compliance) : undefined;
    this.hasLegalPermission ? (this.form.value.groups as FormArray).push(LdapGroups.Legal) : undefined;
    this.hasApproverPermission ? (this.form.value.groups as FormArray).push(LdapGroups.Approver) : undefined;
  }

  getSelectedGroup(group: any) {
    try {
      return this.form.value.ldap === group;
    } catch (error) {
      return false;
    }
  }

  isWithinBatchPeriod() {
    const date = new Date();
    const lowerDifference = BATCH_NOTICE_MINUTE - BATCH_NOTICE_WAIT_PERIOD;
    const upperDifference = BATCH_NOTICE_MINUTE + BATCH_NOTICE_WAIT_PERIOD;

    const lowerHourConstraint = lowerDifference < 0 ? BATCH_NOTICE_HOUR - 1 : BATCH_NOTICE_HOUR;
    const lowerMinuteConstraint = lowerDifference < 0 ? 60 + lowerDifference : lowerDifference;

    const upperHourConstraint = upperDifference >= 60 ? BATCH_NOTICE_HOUR + 1 : BATCH_NOTICE_HOUR;
    const upperMinuteConstraint = upperDifference >= 60 ? upperDifference - 60 : upperDifference;

    const lowerMinuteCondition = date.getMinutes() >= lowerMinuteConstraint && date.getMinutes() <= 59;
    const upperMinuteCondition = date.getMinutes() >= BATCH_NOTICE_MINUTE && date.getMinutes() < upperMinuteConstraint;

    const batchStatus =
      date.getDay() === BATCH_NOTICE_DAY &&
      date.getHours() >= lowerHourConstraint &&
      date.getHours() <= upperHourConstraint &&
      (lowerMinuteCondition || upperMinuteCondition);

    this.batchProcessRunning = batchStatus;
    return batchStatus;
  }

  modifyCampaign(campaign: Campaign, index: number) {
    (window as any).dashboardRequestIndicator.show();
    const saveCampaign: any = { ...campaign };
    saveCampaign.segments = campaign.segments.map((segment) => segment.id);
    this.subscriptions.add(
      this.reviewService.updateCampaign(saveCampaign).subscribe({
        next: (result: any) => {
          (window as any).dashboardRequestIndicator.hide();
          this.campaigns[index].status = result.status;
        },
        error: () => {
          (window as any).dashboardRequestIndicator.hide();
          this.openErrorModal = true;
        },
        complete: () => {
          (window as any).dashboardRequestIndicator.hide();
        },
      }),
    );
  }

  resetErrorModal(): void {
    this.openErrorModal = false;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
