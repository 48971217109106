<bolt-waiting-indicator id="reviewRequestIndicator" fullscreen starthidden></bolt-waiting-indicator>
<div *ngIf="(!segment.group && user.name) || segment.group">
  <div class="segmentTop custom-row">
    <div class="bolt-col create-padding">
      <p class="createMessage">Create your message</p>
    </div>
    <div *ngIf="!segment.group; else otherSegment">
      <div class="scrollable-container">
        <div class="bolt-col-auto">
          <div class="memberInfo">
            <p class="info">Member Info</p>
            <hr class="segmentLine" />
            <div class="text-format">
              <p class="bold">Name: &nbsp;</p>
              <p class="answer">{{ user.name | titlecase }}</p>
            </div>
            <div class="text-format">
              <p class="bold">Products: &nbsp;</p>
              <div *ngFor="let product of user.products; let i = index">
                <p class="answer">{{ product | titlecase }}<span *ngIf="i < user.products.length - 1">,&nbsp;</span></p>
              </div>
            </div>
            <div class="text-format">
              <p class="bold">City: &nbsp;</p>
              <p class="answer">{{ user.city | titlecase }}</p>
            </div>
            <div class="text-format">
              <p class="bold">State: &nbsp;</p>
              <p class="answer">{{ user.state | uppercase }}</p>
            </div>
            <div class="text-format">
              <p class="bold">Age: &nbsp;</p>
              <p class="answer">{{ user.age ?? 'N/A' }}</p>
            </div>
          </div>
        </div>
        <div *ngFor="let petData of user.petData" class="bolt-col-auto">
          <div class="memberInfo">
            <p class="info">Perk Info</p>
            <hr class="segmentLine" />
            <div class="text-format">
              <p class="bold">Perk Name: &nbsp;</p>
              <p class="answer">{{ campaign.name }}</p>
            </div>
            <div class="text-format">
              <p class="bold">Pet Name: &nbsp;</p>
              <p class="answer">{{ petData.name | titlecase }}</p>
            </div>
            <div class="text-format">
              <p class="bold">Type of Pet: &nbsp;</p>
              <p class="answer">{{ petData.species | titlecase | lbsPipe }}</p>
            </div>
            <div class="text-format">
              <p class="bold">Breed: &nbsp;</p>
              <p class="answer">{{ petData.breed | titlecase | lbsPipe }}</p>
            </div>
            <div class="text-format">
              <p class="bold">Age: &nbsp;</p>
              <p class="answer">{{ petData.age }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #otherSegment>
      <div class="infoContainer">
        <p class="info">Segment Info</p>
        <hr class="segmentLine" />
        <bolt-icon class="icon" name="paw"></bolt-icon>
        <div class="text-format">
          <p class="bold">Perk: &nbsp;</p>
          <p class="answer">{{ campaign.name }}</p>
        </div>
        <div class="text-format">
          <p class="bold">Segment: &nbsp;</p>
          <p class="answer">{{ this.segment.tags.join(', ') | titlecase }}</p>
        </div>
      </div>
    </ng-template>
  </div>
  <div class="bolt-container" style="padding-bottom: 50px">
    <div [formGroup]="messageForm">
      <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center" animationDuration="0ms" dynamicHeight>
        <!-- Message Tab -->
        <mat-tab label="Message">
          <div class="bolt-row bolt-justify-content-center">
            <div class="bolt-col-md-auto">
              <bolt-textfield
                required
                [error]="this.messageForm.controls.title.errors?.['message'] || ''"
                label="Push Notification Title"
                spellcheck="true"
                maxlength="65"
                inputsize="95"
                ngDefaultControl
                formControlName="title"
              ></bolt-textfield>
              <div class="show-count">Characters remaining: {{ 65 - (messageForm.get('title')?.value?.length ?? 0) }}</div>
              <p class="helpText">Must be less than 65 characters and begin with "Nationwide® Perks:"</p>
            </div>
          </div>
          <div class="bolt-row bolt-justify-content-center">
            <div class="bolt-col-md-auto">
              <bolt-textarea
                required
                [error]="this.messageForm.controls.content.errors?.['message'] || ''"
                cols="96"
                label="Push notification content"
                spellcheck="true"
                maxLength="110"
                ngDefaultControl
                formControlName="content"
                style="margin-top: 20px"
                showcount
              ></bolt-textarea>
              <p class="helpText" ngDefaultControl formControlName="comment">Must be less than 110 characters</p>
              <div>
                <bolt-textarea
                  [error]="this.messageForm.controls.comment.errors?.['message'] || ''"
                  label="Comment"
                  style="margin-bottom: 25px; margin-top: 20px"
                  ngDefaultControl
                  maxlength="1000"
                  formControlName="comment"
                ></bolt-textarea>
              </div>
            </div>
          </div>
          <div class="bolt-row bolt-justify-content-start pl-4" *ngIf="this.hasWriterPermission && segment.ai">
            <bolt-button type="secondary" color="charcoal" (click)="regenerateAiMessage()"> Regenerate AI Message </bolt-button>
          </div>
          <div>
            <!-- Approver permissions -->
            <div
              class="bolt-row"
              style="justify-content: right !important; padding-bottom: 20px; margin-right: 100px"
              *ngIf="this.hasApproverPermission"
            >
              <div class="bolt-col bolt-col-sm-auto">
                <bolt-button
                  style="margin-right: 10px"
                  type="primary"
                  (click)="setMessageInformation('writer', 'sent', 'writer', true, ModalType.ChangesRequired, 'Approver changes required to Writer')"
                >
                  Changes required send to Writer
                </bolt-button>
                <bolt-button
                  style="margin-right: 10px"
                  type="primary"
                  [disabled]="this.sendToLegalDisabled"
                  (click)="setMessageInformation('legal', 'sent', 'legal', false, ModalType.Confirm, 'Approver send to Legal')"
                >
                  Send to Legal
                </bolt-button>
                <bolt-button
                  type="primary"
                  [disabled]="this.sendToComplianceDisabled"
                  (click)="setMessageInformation('compliance', 'sent', 'compliance', false, ModalType.Confirm, 'Approver send to Compliance')"
                >
                  Send to Compliance
                </bolt-button>
              </div>
              <div class="bolt-col bolt-col-sm-auto" style="padding-top: 20px">
                <bolt-button
                  style="margin-right: 10px"
                  (click)="setMessageInformation('writer', 'rejected', 'writer', true, ModalType.Rejection, 'Approver reject message')"
                >
                  Reject Message
                </bolt-button>
                <bolt-button
                  type="primary"
                  iconright="send"
                  [disabled]="this.disableApproveMessage"
                  (click)="setMessageInformation('queue', 'approved', 'approved', false, ModalType.Confirm, 'Approver approve message')"
                >
                  Approve Message
                </bolt-button>
              </div>
            </div>

            <!-- Writer permissions -->
            <div
              class="bolt-row"
              style="justify-content: right !important; padding-bottom: 20px; margin-right: 100px"
              *ngIf="this.hasWriterPermission"
            >
              <div class="bolt-col bolt-col-sm-auto">
                <bolt-button
                  type="primary"
                  (click)="setMessageInformation('approver', 'sent', 'approver', false, ModalType.Confirm, 'Writer send to Approver')"
                >
                  Send to Approver
                </bolt-button>
              </div>
            </div>

            <!-- Legal AND Compliance permissions -->
            <div
              class="bolt-row"
              style="justify-content: right !important; padding-bottom: 20px; margin-right: 100px"
              *ngIf="this.hasLegalPermission || this.hasCompliancePermission"
            >
              <div class="bolt-col bolt-col-sm-auto">
                <bolt-button
                  style="margin-right: 10px"
                  type="primary"
                  (click)="
                    setMessageInformation(
                      'approver',
                      'sent',
                      'approver',
                      false,
                      ModalType.Confirm,
                      this.hasLegalPermission ? 'Legal review complete to Approver' : 'Compliance review complete to Approver'
                    )
                  "
                >
                  Review Complete
                </bolt-button>
                <bolt-button
                  type="primary"
                  (click)="
                    setMessageInformation(
                      'approver',
                      'sent',
                      'approver',
                      true,
                      ModalType.ChangesRequired,
                      this.hasLegalPermission ? 'Legal changes required to Approver' : 'Compliance changes required to Approver'
                    )
                  "
                >
                  Changes Required
                </bolt-button>
              </div>
            </div>

            <!-- ALL users must has this permission -->
            <div class="bolt-row" style="justify-content: right !important; padding-bottom: 20px; margin-right: 100px">
              <div
                class="bolt-col bolt-col-sm-auto"
                *ngIf="this.hasWriterPermission || this.hasLegalPermission || this.hasCompliancePermission || this.hasApproverPermission"
              >
                <bolt-button type="secondary" (click)="onCancel()"> Cancel </bolt-button>
              </div>
            </div>
          </div>

          <!-- Latest comment / feedback -->
          <div *ngIf="latestHistoryObject !== undefined">
            <bolt-accordion-group>
              <bolt-accordion header="Latest comment" class="dropdown-style" active="true">
                <div class="bolt-row" style="max-width: 600px; justify-content: left !important; margin-bottom: 36px">
                  <app-message-history [history]="latestHistoryObject" [commentClass]="'bolt-row blue-comment'" />
                </div>
              </bolt-accordion>
            </bolt-accordion-group>
          </div>
        </mat-tab>
        <!-- History Tab -->
        <mat-tab label="History">
          <div *ngFor="let history of message.history">
            <app-message-history [history]="history" [commentClass]="'bolt-row gray-comment'" />
          </div>
          <div class="text-center bolt-heading-md bolt-serif">
            <div *ngIf="!message?.history || message?.history?.length === 0">No history</div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>

<bolt-modal id="confirmModal" [type]="this.iconType" heading="Almost Done!" [open]="openConfirmModal">
  <bolt-button-bar slot="modal-button-bar">
    <bolt-button
      *ngIf="!segment.group && segment.needsReview > 1"
      slot="forward"
      type="primary"
      (click)="sendMessage(ModalType.Confirm, 'nextReview')"
    >
      Submit & Next Review</bolt-button
    >
    <bolt-button slot="back" (click)="sendMessage(ModalType.Confirm, 'dashboard')">Submit & Return</bolt-button>
    <bolt-button slot="cancel" (click)="resetModal(ModalType.Confirm, false)">Cancel</bolt-button>
  </bolt-button-bar>
  <p *ngIf="status !== 'approved'">
    Click "Submit & Return" or "Submit & Next Review" to send to {{ status.charAt(0).toUpperCase() + status.slice(1) }}, or "Cancel" to return
  </p>
  <p *ngIf="status === 'approved'">{{ approverApproveModalText }}</p>

  <p class="comment">{{ this.messageForm.getRawValue().content }}</p>
</bolt-modal>

<bolt-modal id="changesRequiredModal" [type]="this.iconType" [open]="openChangesRequiredModal" heading="Almost Done!">
  <bolt-button-bar slot="modal-button-bar">
    <bolt-button
      *ngIf="!segment.group && segment.needsReview > 1"
      slot="forward"
      type="primary"
      (click)="sendMessage(ModalType.ChangesRequired, 'nextReview')"
    >
      Submit & Next Review</bolt-button
    >
    <bolt-button slot="back" (click)="sendMessage(ModalType.ChangesRequired, 'dashboard')">Submit & Return</bolt-button>
    <bolt-button slot="cancel" (click)="resetModal(ModalType.ChangesRequired, false)">Cancel</bolt-button>
  </bolt-button-bar>
  <p *ngIf="group === 'approver'">Click "Submit & Return" or "Submit & Next Review" to send to Writer, or "Cancel" to return</p>
  <p *ngIf="group === 'legal' || group === 'compliance'">
    Click "Submit & Return" or "Submit & Next Review" to send to Approver, or "Cancel" to return
  </p>
  <p class="comment">{{ this.messageForm.getRawValue().comment }}</p>
</bolt-modal>

<bolt-modal id="infoRejectModal" [type]="this.iconType" [open]="openRejectionModal" heading="Almost Done!">
  <bolt-button-bar slot="modal-button-bar">
    <bolt-button
      *ngIf="!segment.group && segment.needsReview > 1"
      slot="forward"
      type="primary"
      (click)="sendMessage(ModalType.Rejection, 'nextReview')"
    >
      Submit & Next Review</bolt-button
    >
    <bolt-button slot="back" (click)="sendMessage(ModalType.Rejection, 'dashboard')">Submit & Return</bolt-button>
    <bolt-button slot="cancel" (click)="resetModal(ModalType.Rejection, false)">Cancel</bolt-button>
  </bolt-button-bar>
  <p>Click "Submit & Return" or "Submit & Next Review" to reject the message, or "Cancel" to return</p>
  <p class="comment">{{ this.messageForm.getRawValue().content }}</p>
</bolt-modal>

<bolt-modal id="messageRefreshModal" [type]="this.iconType" [open]="openMessageRefreshModal" heading="Are you still there?">
  <bolt-button-bar slot="modal-button-bar">
    <bolt-button slot="forward" type="primary" (click)="refreshMessageSession()" [disabled]="!this.message.id">Continue</bolt-button>
    <bolt-button slot="cancel" (click)="resetModal(ModalType.RefreshMessage, false)">Back To Dashboard</bolt-button>
  </bolt-button-bar>
  <p>Click "Continue" to maintain your session</p>
</bolt-modal>

<bolt-modal
  id="errorModal"
  type="error"
  heading="Error"
  primarybutton="Close"
  [open]="openErrorModal"
  (bolt-modal-primary-button-click)="resetModal(ModalType.Error, false)"
>
  <p>User not found.</p>
</bolt-modal>

<bolt-modal
  id="emptyQueueModal"
  type="error"
  heading="Error"
  primarybutton="Close"
  [open]="openEmptyQueueModal"
  (bolt-modal-primary-button-click)="resetModal(ModalType.EmptyQueue, false)"
>
  <p>No Messages In Queue</p>
</bolt-modal>

<bolt-modal
  id="requestErrorModal"
  type="error"
  heading="Error"
  primarybutton="Close"
  [open]="openRequestErrorModal"
  (bolt-modal-primary-button-click)="resetModal(ModalType.RequestError, false)"
>
  <p>There was an issue processing your request.</p>
</bolt-modal>

<bolt-modal id="leaveModal" heading="Leave page?" [open]="openLeaveModal">
  <p>Changes you made may not be saved</p>
  <bolt-button-bar slot="modal-button-bar">
    <bolt-button slot="forward" type="primary" (click)="turnOffRouteGuard()">Confirm</bolt-button>
    <bolt-button slot="back" (click)="onCancel()">Cancel</bolt-button>
  </bolt-button-bar>
</bolt-modal>

<bolt-modal
  id="timeoutModal"
  heading="Review Expired"
  type="error"
  [open]="openTimeoutModal"
  primarybutton="Back To Dashboard"
  (bolt-modal-primary-button-click)="setMessageInformation('', 'timeOut', '', false, '')"
>
  <p>Your Review has expired</p>
</bolt-modal>
