import { Injectable } from '@angular/core';
import { LogLevel, StsConfigStaticLoader } from 'angular-auth-oidc-client';
import { ConfigService } from '../shared/config.service';

@Injectable()
export class AuthService {
  constructor(private readonly config: ConfigService) {}

  createConfigLoader() {
    return new StsConfigStaticLoader({
      authority: this.config.app.secrets.oidc.authority,
      redirectUrl: `${window.location.origin}/sso-authorization`,
      postLogoutRedirectUri: window.location.origin,
      clientId: this.config.app.secrets.clientId,
      scope: 'openid',
      responseType: 'id_token token',
      silentRenew: true,
      silentRenewUrl: `${window.location.origin}/silent-renew.html`,
      renewTimeBeforeTokenExpiresInSeconds: 30,
      startCheckSession: true,
      logLevel: LogLevel.None,
      customParamsAuthRequest: {
        auth_method: this.config.app.secrets.oidc.authMethod,
        realm: this.config.app.secrets.oidc.realm,
        ttl: this.config.app.secrets.oidc.ttl,
      },
    });
  }
}
