import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ConfigService } from 'src/app/shared/config.service';
import { ReviewComponent } from './components/review/review.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'personalized-push-notifications';

  constructor(
    public oidcSecurityService: OidcSecurityService,
    private readonly config: ConfigService,
    private router: Router,
    private reviewComponent: ReviewComponent,
  ) {}

  ngOnInit(): void {
    this.oidcSecurityService.checkAuth().subscribe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ({ isAuthenticated, userData, accessToken, idToken, configId }) => {
        let writer = false;
        let compliance = false;
        let legal = false;
        let approver = false;
        for (let i = 0; i < userData.groups.length; i++) {
          writer = writer || this.config.app.secrets.writerLdap === userData.groups[i];
          compliance = compliance || this.config.app.secrets.complianceLdap === userData.groups[i];
          legal = legal || this.config.app.secrets.legalLdap === userData.groups[i];
          approver = approver || this.config.app.secrets.approverLdap === userData.groups[i];
        }
        if (!writer && !compliance && !legal && !approver) {
          this.router.navigateByUrl('/unauthorized');
        }
        sessionStorage.setItem('hasWriterPermission', writer === false ? 'false' : 'true');
        sessionStorage.setItem('hasCompliancePermission', compliance === false ? 'false' : 'true');
        sessionStorage.setItem('hasLegalPermission', legal === false ? 'false' : 'true');
        sessionStorage.setItem('hasApproverPermission', approver === false ? 'false' : 'true');
        sessionStorage.setItem('displayName', userData.displayName);
      },
    );
  }
}
