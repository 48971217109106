<bolt-header layout="fluid">
  <div slot="logo">
    <bolt-logo href="/dashboard" class="bolt-header-desktop-only" [routerLink]="'/dashboard'"></bolt-logo>
    <bolt-logo href="/dashboard" class="bolt-header-mobile-only" [routerLink]="'/dashboard'" type="nw-mark"></bolt-logo>
    <span class="divider"></span>
    <ul>
      <li><a href="/dashboard" [routerLink]="'/dashboard'">Dashboard</a></li>
    </ul>
  </div>
  <div slot="main-actions">
    <ul>
      <bolt-button type="tertiary" onClick="window.customModal.openModal()">Log Out</bolt-button>
    </ul>
  </div>
</bolt-header>

<bolt-modal id="customModal" heading="Log Out?">
  <p>Are you sure you want to log out?</p>
  <bolt-button-bar slot="modal-button-bar">
    <bolt-button slot="forward" type="primary" href="https://inside.nationwide.com">Logout</bolt-button>
    <bolt-button slot="back" onclick="window.customModal.closeModal()">Cancel</bolt-button>
  </bolt-button-bar>
</bolt-modal>
<router-outlet></router-outlet>
