import { Component, Input } from '@angular/core';
import { MessageHistory } from '../types/message';
@Component({
  selector: 'app-message-history',
  templateUrl: './messages-history.component.html',
  styleUrls: ['./messages-history.component.scss'],
})
export class MessageHistoryComponent {
  @Input() history: MessageHistory = {} as MessageHistory;
  @Input() commentClass!: string;

  sentenceCase(word: string) {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }
}
