export const IIQ_LINK = 'https://iiq.nwie.net/';
export const BATCH_NOTICE_DAY = 3;
export const BATCH_NOTICE_HOUR = 11;
export const BATCH_NOTICE_MINUTE = 0;
export const BATCH_NOTICE_WAIT_PERIOD = 10;
export const INDIVIDUAL_SEGMENTS = [5, 10];

export enum LdapGroups {
  Compliance = 'compliance',
  Legal = 'legal',
  Writer = 'writer',
  Approver = 'approver',
}

export enum Actions {
  Sent = 'sent',
  Rejected = 'rejected',
  Approved = 'approved',
}
export enum ModalType {
  ChangesRequired = 'changesRequiredModal',
  Rejection = 'rejectionModal',
  Error = 'errorModal',
  RequestError = 'requestErrorModal',
  EmptyQueue = 'emptyQueueModal',
  Confirm = 'confirmModal',
  RefreshMessage = 'refreshMessageModal',
}
