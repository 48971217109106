<bolt-waiting-indicator id="dashboardRequestIndicator" fullscreen starthidden></bolt-waiting-indicator>
<html lang="en" class="bolt-typography page-height">
  <div class="bolt-container-fluid">
    <div class="bolt-space-inner-4xl">
      <div [formGroup]="form">
        <div ngDefaultControl formControlName="ldap">
          <bolt-select class="group-dropdown" (change)="roleSelected()" label="Role Access" placeholder="Select a group" required>
            <option [selected]="getSelectedGroup(group)" *ngFor="let group of this.form.value.groups" value="{{ group }}">
              {{ group }}
            </option>
          </bolt-select>
        </div>
        <div class="bolt-heading-lg" *ngIf="!this.form.value.ldap">Please select a group to view campaigns.</div>
      </div>
      <div *ngIf="this.form.value.ldap && this.campaigns.length > 0">
        <h1 class="bolt-heading-lg bolt-sans-serif">Needs Review</h1>
        <h1 class="bolt-heading-lg bolt-sans-serif">Pet</h1>
        <bolt-accordion-group>
          <bolt-accordion *ngFor="let campaign of campaigns; index as i" header="{{ campaign.name }}" active="true">
            <div *ngIf="form.get('ldap')?.value === 'approver'" style="display: flex; align-items: center">
              <bolt-button class="bottom-padding" (click)="modifyCampaign(campaign, i)" [disabled]="isSecondWednesday && isWithinBatchPeriod()">
                {{ campaign.status ? 'Stop campaign' : 'Start campaign' }}
              </bolt-button>
              <p *ngIf="batchProcessRunning" heading="Stop Campaign Disabled" style="padding-left: 10px">
                The campaign cannot be stopped and messages cannot be reviewed within 10 minutes of the scheduled batch process.
              </p>
            </div>
            <div class="scrollable-container bottom-padding">
              <div *ngFor="let segment of campaign.segments">
                <mat-card class="segment-card">
                  <div>
                    <mat-card-header class="mat-header">
                      <mat-card-title class="card-title"
                        ><strong>{{ segment.tags.join(', ') }}</strong
                        ><br
                      /></mat-card-title>
                    </mat-card-header>
                  </div>

                  <div class="flex-container">
                    <mat-card-content>
                      <div class="bolt-row">
                        <div class="bolt-col flex-basis">
                          <p>User Count</p>
                          <p class="number">{{ segment.userCount }}</p>
                        </div>
                        <div *ngIf="segment.group" class="bolt-col flex-basis">
                          <p>Status</p>
                          <p class="number">{{ segment.status }}</p>
                        </div>
                        <div *ngIf="!segment.group" class="bolt-col flex-basis">
                          <p>Needs Review</p>
                          <p class="number">{{ segment.needsReview }}</p>
                        </div>
                        <div *ngIf="!segment.group" class="bolt-col flex-basis">
                          <p>Reviewed</p>
                          <p class="number">{{ segment.reviewed }}</p>
                        </div>
                      </div>
                    </mat-card-content>
                    <div class="bolt-space-inner-2xl">
                      <bolt-button
                        [disabled]="
                          segment.needsReview === 0 || !this.form.value.ldap || !campaign.status || (isSecondWednesday && isWithinBatchPeriod())
                        "
                        (click)="navigate(segment, campaign, 'pet')"
                        color="mint"
                        type="primary"
                        class="w-full"
                        >Start Review</bolt-button
                      >
                      <bolt-button
                        *ngIf="segment.group && form.get('ldap')?.value === 'approver'"
                        [disabled]="!segmentInReview(segment) || (isSecondWednesday && isWithinBatchPeriod())"
                        (click)="cancelMessage(segment)"
                        color="mint"
                        class="w-full mt-2"
                        >Cancel Message</bolt-button
                      >
                    </div>
                  </div>
                </mat-card>
              </div>
            </div>
          </bolt-accordion>
        </bolt-accordion-group>
      </div>
    </div>
  </div>
</html>

<bolt-modal
  id="errorModal"
  type="error"
  heading="Error"
  primarybutton="Close"
  [open]="openErrorModal"
  (bolt-modal-primary-button-click)="resetErrorModal()"
>
  <p>There was an issue processing your request.</p>
</bolt-modal>
