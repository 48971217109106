import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class MessageValidators {
  titleValidator(): ValidatorFn {
    const titlePattern = new RegExp(/^Nationwide® Perks:/);
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.dirty && !control.touched) {
        return null;
      }
      if (control.value.length > 0 && control.value.length <= 65) {
        const requiredFormat = titlePattern.test(control.value);
        if (!requiredFormat) {
          return { invalidTitle: true, message: 'Push notification title must begin with "Nationwide® Perks:"' };
        } else {
          return null;
        }
      } else {
        return { invalidTitle: true, message: 'Push notification title cannot be blank.' };
      }
    };
  }

  contentValidator(): ValidatorFn {
    const contentPattern = new RegExp(/.*\\*[a-zA-z0-9 ]\W?/);
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.dirty && !control.touched) {
        return null;
      }
      if (control.value.length > 0 && control.value.length <= 110 && contentPattern.test(control.value)) {
        return null;
      } else {
        return { invalidContent: true, message: 'Push notification content cannot be blank.' };
      }
    };
  }

  commentValidator(commentIsRequired: () => boolean | null): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (commentIsRequired()) {
        const pattern = new RegExp(/.*\\*[a-zA-z0-9 ]\W?/);
        if (control.value.length <= 1000 && control.value.length > 0 && pattern.test(control.value)) {
          return null;
        } else {
          return { invalidComment: true, message: 'Comment cannot be blank.' };
        }
      } else {
        return null;
      }
    };
  }
}
