import { Location } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { firstValueFrom, Subscription } from 'rxjs';
import { ReviewService } from 'src/app/services/review.service';
import { Actions, LdapGroups, ModalType } from 'src/app/shared/constants';
import { MessageValidators } from 'src/app/shared/validators';
import { AiData, Campaign, Message, MessageHistory, PushNotificationNavState, Segment, User } from '../types';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReviewComponent implements OnInit, OnDestroy {
  latestHistoryObject?: MessageHistory;
  latestDate = 0;
  openMessageRefreshModal = false;
  openErrorModal = false;
  openEmptyQueueModal = false;
  openRequestErrorModal = false;
  openConfirmModal = false;
  openChangesRequiredModal = false;
  openApprovalModal = false;
  openRejectionModal = false;
  openTimeoutModal = false;
  openLeaveModal = false;
  hasUnsavedChanges = false;
  isDisabled = true;
  sentTo = '';
  group = '';
  segment: Segment = {} as Segment;
  campaign: Campaign = {} as Campaign;
  user: User = {} as User;
  message: Message = {} as Message;
  sortedHistory: MessageHistory[] = [];
  subscriptions: Subscription = new Subscription();

  approverApproveModalText = '';

  sendToLegalDisabled = false;
  sendToComplianceDisabled = false;
  disableApproveMessage = false;

  originalAiData?: AiData;
  hasWriterPermission = false;
  hasCompliancePermission = false;
  hasLegalPermission = false;
  hasApproverPermission = false;
  recipient = '';
  action = '';
  status = '';
  actionDescription = '';
  iconType = '';
  ModalType = ModalType;

  pageTimeoutHandler?: NodeJS.Timeout;
  refreshTimeoutHandler?: NodeJS.Timeout;

  constructor(
    private location: Location,
    private fb: FormBuilder,
    private router: Router,
    private reviewService: ReviewService,
    private validators: MessageValidators,
  ) {}

  commentIsRequired = false;

  messageForm = this.fb.group({
    id: [''],
    approvalTime: [0],
    created: [0],
    lastUpdated: [0],
    campaign: [''],
    campaignStatus: [false],
    ecn: [''],
    segmentId: [''],
    status: [''],
    history: this.fb.array([]),
    title: ['', this.validators.titleValidator()],
    content: ['', this.validators.contentValidator()],
    comment: ['', this.validators.commentValidator(() => this.commentIsRequired)],
  });

  ngOnInit() {
    this.checkRole();
    const state = this.location.getState() as PushNotificationNavState | undefined;

    if (!state) {
      this.router.navigate(['/dashboard']);
      return;
    }

    this.campaign = state.campaign;
    this.segment = state.segment;
    this.group = state.group;
    this.getMessage().then(() => {
      this.pageTimeoutHandler = this.getTimeoutForPage();
      this.refreshTimeoutHandler = this.getTimeoutForRefresh();
    });
  }

  sortMessageHistory() {
    if (this.message?.history) {
      this.sortedHistory = JSON.parse(JSON.stringify(this.message.history));
      this.sortedHistory.sort((a, b) => b.created - a.created);
    }
  }

  async getMessage(regenerateAiMessage?: boolean) {
    try {
      (window as any).reviewRequestIndicator.show();
      let message = await firstValueFrom(this.reviewService.getMessageFromQueue(this.group, this.segment.id, this.campaign.id));

      if (!message && this.group !== LdapGroups.Writer) {
        // user is not a writer, thus should have a message in the queue.  If not, show error modal
        (window as any).reviewRequestIndicator.hide();
        this.openEmptyQueueModal = true;
        return;
      } else if (!message) {
        // user is a writer, but no message in the queue.  Create a new message
        message = {
          campaign: this.campaign.id,
          campaignStatus: this.campaign.status,
          segmentId: this.segment.id,
        } as Message;
      } else {
        // loading existing valid message, enable our buttons and set timeout
        this.isDisabled = false;
      }
      this.message = { ...this.message, ...message };
      await this.setUser(regenerateAiMessage);
      this.findLatestHistory(this.message);
    } catch (error) {
      (window as any).reviewRequestIndicator.hide();
      this.openEmptyQueueModal = true;
    }
  }

  async setUser(regenerateAiMessage?: boolean) {
    if (!this.segment.group) {
      const ai = this.message.id ? false : this.segment.ai;
      (window as any).reviewRequestIndicator.show();
      try {
        const user = await firstValueFrom(this.reviewService.getUser(this.segment.id, this.campaign.id, ai, this.message, regenerateAiMessage));
        this.user = user;
        this.messageForm.get('ecn')?.patchValue(this.user.ecn);

        // no pre-loaded message from getMessage, use the new one returned by the /start-review
        if (!this.message.id || regenerateAiMessage) {
          this.message = user.message;
        }

        if (ai || regenerateAiMessage) {
          this.messageForm.get('title')?.patchValue(this.user.message.title);
          this.messageForm.get('content')?.patchValue(this.user.message.content);

          this.originalAiData = this.message.aiData;
        }

        this.setFormValues();
        this.handleApproverLogic();

        (window as any).reviewRequestIndicator.hide();
      } catch (error) {
        (window as any).reviewRequestIndicator.hide();
        this.openErrorModal = true;
      }
    } else {
      this.setFormValues();
      this.handleApproverLogic();
      (window as any).reviewRequestIndicator.hide();
    }
  }

  setFormValues() {
    if (Object.keys(this.message).length > 0) {
      this.messageForm.patchValue(this.message);
    }

    if (this.group !== LdapGroups.Writer) {
      this.messageForm.get('title')?.disable();
      this.messageForm.get('content')?.disable();
    }
  }

  handleApproverLogic() {
    if (this.group === LdapGroups.Approver) {
      this.sortMessageHistory();
      this.disableApproveMessage = false;
      this.sendToLegalDisabled = this.checkDisableApprovalSteps(LdapGroups.Legal);
      this.sendToComplianceDisabled = this.checkDisableApprovalSteps(LdapGroups.Compliance);
      this.approverApproveModalText = this.getApproverApprovedModalText();
    }
  }

  routeHandler(route: string) {
    this.router.navigate([route]);
  }

  checkRole() {
    this.hasWriterPermission = sessionStorage.getItem('ldapGroup') === LdapGroups.Writer;
    this.hasCompliancePermission = sessionStorage.getItem('ldapGroup') === LdapGroups.Compliance;
    this.hasLegalPermission = sessionStorage.getItem('ldapGroup') === LdapGroups.Legal;
    this.hasApproverPermission = sessionStorage.getItem('ldapGroup') === LdapGroups.Approver;
  }

  sentenceCase(word: string) {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

  findLatestHistory(message: Message) {
    this.latestHistoryObject = message.history?.length > 0 ? message.history[0] : undefined;
  }

  resetModal(modalType: ModalType, value: boolean): void {
    const modalToDashboardMap = {
      [ModalType.ChangesRequired]: { routeToDashboard: false },
      [ModalType.Rejection]: { routeToDashboard: false },
      [ModalType.Error]: { routeToDashboard: true },
      [ModalType.RequestError]: { routeToDashboard: true },
      [ModalType.EmptyQueue]: { routeToDashboard: true },
      [ModalType.Confirm]: { routeToDashboard: false },
      [ModalType.RefreshMessage]: { routeToDashboard: true },
    };

    this.handleModal(modalType, value);

    if (modalToDashboardMap[modalType].routeToDashboard) {
      this.router.navigate(['/dashboard']);
    }
  }

  handleModal(modalType: string, value: boolean) {
    switch (modalType) {
      case ModalType.ChangesRequired:
        this.openChangesRequiredModal = value;
        break;
      case ModalType.Rejection:
        this.openRejectionModal = value;
        break;
      case ModalType.Error:
        this.openErrorModal = value;
        break;
      case ModalType.RequestError:
        this.openRequestErrorModal = value;
        break;
      case ModalType.EmptyQueue:
        this.openEmptyQueueModal = value;
        break;
      default:
        this.openConfirmModal = value;
        break;
    }
  }

  handleIconType(modalType: string): void {
    switch (modalType) {
      case 'changesRequiredModal':
      case 'rejectionModal':
        this.iconType = 'info';
        break;
      default:
        this.iconType = 'confirm';
        break;
    }
  }

  setMessageInformation(recipient: string, action: string, status: string, commentIsRequired: boolean, modalType: string, actionDescription = '') {
    this.recipient = recipient;
    this.action = action;
    this.status = status;
    this.actionDescription = actionDescription;
    this.commentIsRequired = commentIsRequired;

    this.handleIconType(modalType);
    this.messageForm.markAllAsTouched();
    this.updateValueAndValidity();
    this.showModal(modalType);
  }

  updateValueAndValidity() {
    for (const controlName in this.messageForm.controls) {
      this.messageForm.get(controlName)?.updateValueAndValidity();
    }
  }

  showModal(modalType: string) {
    if (this.action === 'timeOut') {
      this.sendMessage(modalType, 'dashboard');
    } else {
      if (this.messageForm.valid) {
        this.handleModal(modalType, true);
      }
    }
  }

  async nextReview() {
    if (this.message.id) {
      try {
        (window as any).reviewRequestIndicator.show();
        await firstValueFrom(this.reviewService.resetQueueMessage(this.message.id));
        (window as any).reviewRequestIndicator.hide();
      } catch (err) {
        (window as any).reviewRequestIndicator.hide();
        this.openRequestErrorModal = true;
      }
    }
    this.messageForm.reset({
      id: '',
      approvalTime: 0,
      created: 0,
      lastUpdated: 0,
      campaign: '',
      campaignStatus: false,
      ecn: '',
      segmentId: '',
      history: [],
      title: '',
      content: '',
      comment: '',
    });
    this.segment.needsReview -= 1;
    this.messageForm.updateValueAndValidity();
    this.commentIsRequired = false;
    this.message = {} as Message;
    this.user = {} as User;
    this.originalAiData = undefined;
    this.getMessage();
  }

  sendMessage(modalType: string, button: string) {
    this.handleModal(modalType, false);
    this.sentTo = this.recipient;
    this.messageForm.get('status')?.patchValue(this.status);
    if (this.action === Actions.Approved) {
      const approvalTime = Date.now();
      this.messageForm.get('approvalTime')?.patchValue(approvalTime);
    } else if (this.action === Actions.Rejected) {
      this.messageForm.get('content')?.patchValue('');
      this.messageForm.get('title')?.patchValue('');
    }
    const message = this.messageForm.getRawValue() as Message;
    const history = {
      user: sessionStorage.getItem('displayName') ?? '',
      group: this.group,
      recipient: this.recipient,
      action: this.action,
      description: this.actionDescription,
      userCount: this.segment.userCount,
      comment: message.comment,
      inReview: false,
      assignedTo: '',
      aiDataChanged: this.originalAiData ? false : undefined,
    };
    (window as any).reviewRequestIndicator.show();

    if (this.originalAiData) {
      message.aiData = this.originalAiData;

      message.aiDataChanged = message.title !== this.originalAiData.title || message.content !== this.originalAiData.body;
      history.aiDataChanged = message.aiDataChanged;
    }

    let saveMessage$;
    if (message.id) {
      saveMessage$ = this.reviewService.updateMessageStatus(message, history, this.segment.id);
    } else {
      saveMessage$ = this.reviewService.sendMessage(message, history, this.segment.id);
    }

    this.subscriptions.add(
      saveMessage$.subscribe({
        next: (message: Message) => {
          this.message = message;
          (window as any).reviewRequestIndicator.hide();
          button == 'dashboard' ? this.routeHandler('dashboard') : this.nextReview();
        },
        error: () => {
          (window as any).reviewRequestIndicator.hide();
          this.openRequestErrorModal = true;
        },
      }),
    );
  }

  @HostListener('window:popstate', ['$event'])
  onPopState() {
    if (this.messageForm.dirty) {
      this.hasUnsavedChanges = true;
      this.openLeaveModal = true;
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event: any) {
    if (this.messageForm.dirty) {
      event.preventDefault();
    }
  }

  turnOffRouteGuard() {
    this.openLeaveModal = false;
    this.hasUnsavedChanges = false;
    this.router.navigate([`/dashboard`]);
  }

  onCancel() {
    this.openLeaveModal = false;
    this.hasUnsavedChanges = false;

    if (this.message.id) {
      this.reviewService.resetQueueMessage(this.message.id);
    }
    history.pushState({}, '');
    this.router.navigate([`/dashboard`]);
  }

  // Disable buttons for approval users if the related role has already approved the message
  checkDisableApprovalSteps(group: string) {
    // check to see if we hit any of the override conditions
    const override = this.checkApproverDisableOverride();

    if (override) {
      return true;
    }
    const hasFormErrors = this.messageForm.get('title')?.errors || this.messageForm.get('content')?.errors;

    if (this.sortedHistory.length === 0) {
      return false;
    }

    const foundUserHistory = this.sortedHistory.find((history) => {
      return history.group === group && history.action === Actions.Sent;
    });

    const foundWriterHistory = this.sortedHistory.find((history) => {
      return history.group === LdapGroups.Writer && history.action === Actions.Sent;
    });

    // If we found history, check if the group has touched the message after the writer.
    const otherUserHasTouched = (foundUserHistory?.created ?? 0) > (foundWriterHistory?.created ?? 0);

    /*
     * If Legal/compliance has touched the message after the writer and the message is in changes required, disable the approve button.
     * This is to prevent the approver from approving the message before the writer has made the changes.
     */
    if (otherUserHasTouched && foundUserHistory?.description.toLowerCase().includes('changes required')) {
      this.disableApproveMessage = true;
    }

    return otherUserHasTouched || !!hasFormErrors;
  }

  // Check if either legal or compliance rejected the message.  If so all buttons should be enabled (return false).
  checkApproverDisableOverride() {
    // no history, no override.
    if (this.sortedHistory.length === 0) {
      return false;
    }

    const foundLegal = this.sortedHistory.find((history) => {
      return history.group === LdapGroups.Legal;
    });

    const foundCompliance = this.sortedHistory.find((history) => {
      return history.group === LdapGroups.Compliance;
    });

    const foundWriter = this.sortedHistory.find((history) => {
      return history.group === LdapGroups.Writer;
    });

    // If we have history we should always have a writer action, but better safe than sorry.
    if (!foundLegal || !foundCompliance || !foundWriter) {
      return false;
    }

    if (foundWriter.created > foundLegal.created || foundWriter.created > foundCompliance.created) {
      // Writer has taken action since legal and/or compliance.  don't override.
      return false;
    }

    return foundCompliance.action !== foundLegal.action && (foundCompliance.action === Actions.Rejected || foundLegal.action === Actions.Rejected);
  }

  getApproverApprovedModalText() {
    const baseMessage = 'Click "Submit & Return" or "Submit & Next Review" to approve the message, or "Cancel" to return';

    if (!this.sendToLegalDisabled && !this.sendToComplianceDisabled) {
      // Compliance and Legal have not approved yet
      return `The message has not been reviewed by Legal or Compliance. ${baseMessage}`;
    } else if (!this.sendToComplianceDisabled) {
      // Compliance has not yet approved.
      return `The message has not been reviewed by Compliance. ${baseMessage}`;
    } else if (!this.sendToLegalDisabled) {
      // Legal has not yet approved.
      return `The message has not been reviewed by Legal. ${baseMessage}`;
    }

    return baseMessage;
  }

  async processTimeout() {
    this.openTimeoutModal = true;
    this.openMessageRefreshModal = false;
    // fire and forget, we don't need to wait for this to complete before moving on.
    firstValueFrom(this.reviewService.resetQueueMessage(this.message.id));
  }

  getTimeoutForPage() {
    return setTimeout(() => this.processTimeout(), 30 * 60 * 1000);
  }

  getTimeoutForRefresh() {
    return setTimeout(
      () => {
        this.openMessageRefreshModal = true;
      },
      15 * 60 * 1000,
    );
  }

  async ngOnDestroy(): Promise<void> {
    if (this.message.id) {
      await firstValueFrom(this.reviewService.resetQueueMessage(this.message.id));
    }
    this.subscriptions.unsubscribe();
  }

  async refreshMessageSession(): Promise<boolean> {
    window.clearTimeout(this.pageTimeoutHandler);
    this.pageTimeoutHandler = this.getTimeoutForPage();
    window.clearTimeout(this.refreshTimeoutHandler);
    this.refreshTimeoutHandler = this.getTimeoutForRefresh();

    this.openMessageRefreshModal = false;

    return await firstValueFrom(this.reviewService.refreshMessageSession(this.message.id)).then((response) => {
      return !!response;
    });
  }

  async regenerateAiMessage() {
    await this.getMessage(true).then(async () => {
      const message = await firstValueFrom(this.reviewService.getMessageFromQueue(this.group, this.segment.id, this.campaign.id));
      this.message.history = message.history;
      this.findLatestHistory(this.message);
    });
  }
}
