import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ReviewComponent } from './components/review/review.component';
import { SsoAuthorizationComponent } from './components/sso-authorization/sso-authorization.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { ErrorComponent } from './components/error/error.component';
import { AuthGuard } from './shared/auth-guard';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AutoLoginPartialRoutesGuard] },
  {
    path: 'pet',
    children: [
      {
        path: 'review/segment/:id',
        pathMatch: 'full',
        component: ReviewComponent,
        canActivate: [AutoLoginPartialRoutesGuard, AuthGuard],
        canDeactivate: [(component: ReviewComponent) => !component.hasUnsavedChanges],
      },
      { path: '**', component: ErrorComponent, data: { errorMessage: 'The page does not exist.' } },
    ],
  },
  {
    component: SsoAuthorizationComponent,
    path: 'sso-authorization',
  },
  {
    component: UnauthorizedComponent,
    path: 'unauthorized',
  },
  { path: '**', component: ErrorComponent, data: { errorMessage: 'The page does not exist.' } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
