import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthConfigModule } from './auth/auth-config.module';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { MessageHistoryComponent } from './components/messageHistory/messages-history.component';
import { ReviewComponent } from './components/review/review.component';
import { ConfigService } from './shared/config.service';
import { ErrorComponent } from './components/error/error.component';
import { LbsPipe } from './pipes/lbs-case.pipe';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [AppComponent, DashboardComponent, ReviewComponent, ErrorComponent, MessageHistoryComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AuthConfigModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatTabsModule,
    ReactiveFormsModule,
    FormsModule,
    LbsPipe,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigService) => () => configService.load(),
      deps: [ConfigService],
      multi: true,
    },
    ReviewComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
