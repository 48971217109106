import { Component } from '@angular/core';
import { IIQ_LINK } from 'src/app/shared/constants';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent {
  link = IIQ_LINK;
}
