import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, Subscription, switchMap, throwError } from 'rxjs';
import { v4 } from 'uuid';
import { Campaign, Message, ReducedHistory, User } from '../components/types';
import { ConfigService } from '../shared/config.service';

@Injectable({
  providedIn: 'root',
})
export class ReviewService implements OnDestroy {
  constructor(
    private http: HttpClient,
    private readonly config: ConfigService,
    public oidcSecurityService: OidcSecurityService,
  ) {}

  clientId = this.config.app.secrets.clientId;
  messageId = () => v4();
  public url = this.config.app.secrets.url;
  private subscriptions: Subscription = new Subscription();

  getUser(segmentId: string, campaignId: string, aiMessage: boolean, message?: Message, regenerateAiMessage?: boolean): Observable<User> {
    let tempUrl = this.url + `/start-review?segmentId=${segmentId}&campaignId=${campaignId}&aiMessage=${aiMessage}`;

    if (message && message.id && message.ecn) {
      tempUrl += `&messageId=${message.id}&ecn=${message.ecn}&regenerateAiMessage=${!!regenerateAiMessage}`;
    }

    return this.oidcSecurityService.getAccessToken().pipe(
      switchMap((accessToken) => {
        try {
          return this.http.get<User>(tempUrl, {
            headers: { 'client_id': this.clientId, 'X-NW-Message-ID': this.messageId(), 'Authorization': 'Bearer ' + accessToken },
          });
        } catch (error) {
          return throwError(() => new Error('Error:' + error));
        }
      }),
    );
  }

  getCampaigns(role: string): Observable<Campaign[]> {
    return this.oidcSecurityService.getAccessToken().pipe(
      switchMap((accessToken) => {
        try {
          return this.http.get<Campaign[]>(this.url + `/campaigns?role=${role}`, {
            headers: { 'client_id': this.clientId, 'X-NW-Message-ID': this.messageId(), 'Authorization': 'Bearer ' + accessToken },
          });
        } catch (error) {
          return throwError(() => new Error('Error:' + error));
        }
      }),
    );
  }

  getMessageFromQueue(status: string, segmentId: string, campaignId: string): Observable<Message> {
    return this.oidcSecurityService.getAccessToken().pipe(
      switchMap((accessToken) => {
        try {
          return this.http.get<Message>(this.url + `/queue?status=${status}&segmentId=${segmentId}&campaignId=${campaignId}`, {
            headers: { 'client_id': this.clientId, 'X-NW-Message-ID': this.messageId(), 'Authorization': 'Bearer ' + accessToken },
          });
        } catch (error) {
          return throwError(() => new Error('Error:' + error));
        }
      }),
    );
  }

  resetQueueMessage(messageId: string): Observable<unknown> {
    return this.oidcSecurityService.getAccessToken().pipe(
      switchMap((accessToken) => {
        try {
          return this.http.patch(
            this.url + `/queue/reset`,
            { messageId },
            {
              headers: { 'client_id': this.clientId, 'X-NW-Message-ID': this.messageId(), 'Authorization': 'Bearer ' + accessToken },
            },
          );
        } catch (error) {
          return throwError(() => new Error('Error:' + error));
        }
      }),
    );
  }

  refreshMessageSession(messageId: string): Observable<unknown> {
    return this.oidcSecurityService.getAccessToken().pipe(
      switchMap((accessToken) => {
        try {
          return this.http.post(
            this.url + `/message-heartbeat/` + messageId,
            { messageId },
            {
              headers: { 'client_id': this.clientId, 'X-NW-Message-ID': this.messageId(), 'Authorization': 'Bearer ' + accessToken },
            },
          );
        } catch (error) {
          return throwError(() => new Error('Error:' + error));
        }
      }),
    );
  }

  updateCampaign(campaign: Campaign): Observable<any> {
    const endpoint = campaign.status ? '/stop-campaign' : '/start-campaign';
    return this.oidcSecurityService.getAccessToken().pipe(
      switchMap((accessToken) => {
        try {
          return this.http.post<any>(this.url + endpoint, campaign, {
            headers: { 'client_id': this.clientId, 'X-NW-Message-ID': this.messageId(), 'Authorization': 'Bearer ' + accessToken },
          });
        } catch (error) {
          return throwError(() => new Error('Error:' + error));
        }
      }),
    );
  }

  updateMessageStatus(message: Message, history: ReducedHistory, segmentId: string) {
    return this.oidcSecurityService.getAccessToken().pipe(
      switchMap((accessToken) => {
        try {
          return this.http.patch<Message>(
            this.url + `/message/${message.id}`,
            {
              status: message.status,
              history: history,
              segmentId: segmentId,
              title: message.title,
              content: message.content,
              approvalTime: message.approvalTime,
              campaignStatus: message.campaignStatus,
              aiDataChanged: message.aiDataChanged,
            },
            {
              headers: { 'client_id': this.clientId, 'X-NW-Message-ID': this.messageId(), 'Authorization': 'Bearer ' + accessToken },
            },
          );
        } catch (error) {
          return throwError(() => new Error('Error:' + error));
        }
      }),
    );
  }

  getMessagesFromDb(): Observable<Message[]> {
    return this.oidcSecurityService.getAccessToken().pipe(
      switchMap((accessToken) => {
        try {
          return this.http.get<Message[]>(this.url + `/message`, {
            headers: { 'client_id': this.clientId, 'X-NW-Message-ID': this.messageId(), 'Authorization': 'Bearer ' + accessToken },
          });
        } catch (error) {
          return throwError(() => new Error('Error:' + error));
        }
      }),
    );
  }
  sendMessage(message: Message, history: ReducedHistory, segmentId: string): Observable<Message> {
    return this.oidcSecurityService.getAccessToken().pipe(
      switchMap((accessToken) => {
        if (message.comment) {
          delete message.comment;
        }

        try {
          return this.http.post<Message>(
            this.url + '/message',
            { message: message, history: history, segmentId: segmentId },
            {
              headers: { 'client_id': this.clientId, 'X-NW-Message-ID': this.messageId(), 'Authorization': 'Bearer ' + accessToken },
            },
          );
        } catch (error) {
          return throwError(() => new Error('Error:' + error));
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
